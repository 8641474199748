import React from "react";
import { Layout } from "../components/Layout";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import { ContactPage } from "../components/PageContact";

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <Helmet>
        <meta name="icon" href="/static/favicon.ico" />
      </Helmet>
      <ContactPage />
    </Layout>
  );
};

export default Contact;
