import styled from "styled-components";
import { BLACK, YELLOW } from "../../constants/colors";
import { SM, MD, LG } from "../../constants/mediaQueries";


export const Content = styled.p`
  margin: auto;
  padding-top: 3rem;
  padding-right: 5  rem;
  font-size: 1.5rem;
  font-weight: bold;



  @media (max-width: ${SM}) {
    font-size: 1rem;
    padding: 0;
  }

  a {
    color: ${YELLOW};
    &:hover {
      color: ${YELLOW};
    }
    &:active {
      color: ${YELLOW};
    }
  }
`;


export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const Wrapper = styled.div`
  background-color: ${BLACK};
  color: ${YELLOW};
  padding: 0;
  position: relative;
  text-transform: uppercase;
  overflow: scroll;
`;

export const SectionTitle = styled.h1`
  font-size: 7rem;
  padding-left: 2.5rem;
  padding-top: 2.5rem;
  position: fixed;
  text-align: left;
  max-width: 20vw;
  left: 0;
  top: 0;
  color: ${YELLOW};
  font-weight: bold;
  z-index: 1000;
  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
  }

  @media (max-width: ${MD}) {
    font-size: 5rem;
  }
  @media (max-width: ${SM}) {
    padding: 0.25rem;
    font-size: 4rem;
    width: 100%;
    bottom: unset;
    top: 0;
  }

  @media (max-width: 350px) {
    padding: 0.25rem;
    font-size: 3rem;
  }
`;

